//
// Pager pagination
// --------------------------------------------------


.pager {
  padding-left: 0;
  margin: $line-height-computed 0;
  list-style: none;
  text-align: center;
  @include clearfix;
  font-size: 0;
  li {
    font-size: $font-size-base;
    display: inline-block; // Remove list-style and block-level defaults
    vertical-align: top;
    > a,
    > span,
    > button {
      outline: none;
      text-align: center;
      position: relative;
      display: block;
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-base;
      text-decoration: none;
      color: $pagination-color;
      background: $pagination-bg;
      border: 1px solid $pagination-border;
      margin-left: -1px;
      border-radius: $border-radius-base;
    }

    > a:hover,
    > a:focus, 
    > button:hover,
    > button:focus {
      color: $pagination-hover-color;
      background: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
    > .active > a,
    > .active > span,
    > .active > button {
      &,
      &:hover,
      &:focus {
        z-index: 2;
        color: $pagination-active-color;
        background: $pagination-active-bg;
        border-color: $pagination-active-border;
        cursor: default;
      }
    }
  }

  .next {
    > a,
    > span {
      float: right;
    }
  }

  .previous {
    > a,
    > span {
      float: left;
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: $pager-disabled-color;
      background-color: $pager-bg;
      cursor: $cursor-disabled;
    }
  }
}

@mixin lg {
	@media only screen and (min-width: $screen-lg) {
		@content;
	}
}
@mixin md {
	@media only screen and (max-width: $screen-md) {
		@content;
	}
}
@mixin sm {
	@media only screen and (max-width: $screen-sm) {
		@content;
	}
}
@mixin xs {
	@media only screen and (max-width: $screen-xs) {
		@content;
	}
}


@import 'media';

// media


@font-face {
    font-family: 'FranklinGothic-DemiCond';
    src: url('../fonts/franklingothicdemicondc-webfont.eot');
    src: url('../fonts/franklingothicdemicondc-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/franklingothicdemicondc-webfont.woff2') format('woff2'),
         url('../fonts/franklingothicdemicondc-webfont.woff') format('woff'),
         url('../fonts/franklingothicdemicondc-webfont.ttf') format('truetype'),
         url('../fonts/franklingothicdemicondc-webfont.svg#franklingothicdemicondcRg') format('svg');
    font-weight: normal;
    font-style: normal;

}
$screen-xs:                 480px;
$screen-sm:                 768px;
$screen-md:                 980px;
$screen-lg:                 1240px;

$grid-float-breakpoint:     $screen-md;

$brand-color:           #37aaeb;
$brand-primary:         #37aaeb;
$brand-success:         #40ce7c;
$brand-info:            #a0d3e8;
$brand-warning:         #f08a24;
$brand-danger:          #f04124;



$grid-gutter-width:         20px;
$border-radius-base:        36px;

$font-size-base:          14px;
$font-family-sans-serif:  "Open Sans", Helvetica, Arial, sans-serif;

$gray-base:              #434343;

$body-bg:               #fff;
$link-color:            $brand-color;
$link-hover-decoration: none;

$headings-font-weight:    700;
$headings-line-height:    1.1;
$headings-color:          #000;

$input-bg:                       #ecf0f1;
$input-color:                    #000;
$input-border:                   $input-bg;

$navbar-height:         70px;
$navbar-margin-bottom:      0;
$grid-columns:              12;


$navbar-inverse-color:                      #ecf0f1;
$navbar-inverse-bg:                         #222;
$navbar-inverse-border:                     $navbar-inverse-bg;

// Inverted navbar links
$navbar-inverse-link-color:                 #ecf0f1;
$navbar-inverse-link-hover-color:           $brand-color;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            $brand-color;
$navbar-inverse-toggle-icon-bar-bg:         $brand-color;
$navbar-inverse-toggle-border-color:        $brand-color;

$pagination-color:                     #222;
$pagination-bg:                        transparent;
$pagination-border:                    #d0d0d0;

$pagination-hover-color:               #fff;
$pagination-hover-bg:                  $brand-color;
$pagination-hover-border:              $brand-color;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-color;
$pagination-active-border:             $brand-color;

$well-bg:                     #e6e9ea;
$well-border:                 $well-bg;

$headings-font-family:    'FranklinGothic-DemiCond';
$headings-font-weight:    normal;


$thumbnail-padding:           4px !default;
//** Thumbnail background color
$thumbnail-bg:                $body-bg !default;
//** Thumbnail border color
$thumbnail-border:            #ddd !default;
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius-base !default;

$thumbnail-caption-color:     #888888;
$thumbnail-caption-padding:   9px !default;
// thumb
$gutter-vertical: 50px;
$gutter-vertical-sm: 35px;

// content
@import 'template';

@import "../node_modules/susy/sass/susy";

.container-fluid, .wrapper, .container {
	min-width: 280px;
}
.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
}
.wrapper {
	overflow: hidden;
	padding-top: $navbar-height;
	@media (max-width: $grid-float-breakpoint-max) {
	  padding-top: 40px;
	}

}
@media (max-width: $grid-float-breakpoint-max) {
	.fullscreen .fotorama__nav-wrap {
		display: none !important;
	}
}

.form-control {
	border-width: 2px;
}
.badge {
	vertical-align: middle;
	background: transparent;
	border: 1px solid  #506479;
	color: #24a4ed;
	font-size: 14px;
	padding: 5px 15px;
	font-weight: normal;
	border-radius: 26px;
	font-family: 'Open Sans', sans-serif;
}
@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?h29t37');
	src:url('../fonts/icomoon.eot?#iefixh29t37') format('embedded-opentype'),
		url('../fonts/icomoon.woff?h29t37') format('woff'),
		url('../fonts/icomoon.ttf?h29t37') format('truetype'),
		url('../fonts/icomoon.svg?h29t37#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-cross:before {
	content: "\e60b";
}

.icon-search:before {
	content: "\e600";
}

.icon-favorite:before {
	content: "\e601";
}

.icon-favorite-outline:before {
	content: "\e602";
}

.icon-star:before {
	content: "\e603";
}

.icon-star-half:before {
	content: "\e604";
}

.icon-star-outline:before {
	content: "\e605";
}

.icon-gear:before {
	content: "\e60d";
}

.icon-plus:before {
	content: "\e606";
}

.icon-maximize:before {
	content: "\e607";
}

.icon-eye:before {
	content: "\e608";
}

.icon-resize-100:before {
	content: "\e60c";
}

.icon-arrow-long-right:before {
	content: "\e609";
}

.icon-arrow-long-left:before {
	content: "\e60a";
}

.icon-home:before {
	content: "\f015";
}

.icon-angle-left:before {
	content: "\f104";
}

.icon-angle-right:before {
	content: "\f105";
}

.icon-angle-up:before {
	content: "\f106";
}

.icon-angle-down:before {
	content: "\f107";
}

h1, .h1 {
	@include sm {
		font-size: 24px;
	}
}
h2, .h2 {
	@include sm {
		font-size: 20px;
	}
}
h3, .h3 {
	@include sm {
		font-size: 18px;
	}
}

.form-control {
	box-shadow: none;
}

.row-content {
	@include clearfix;
}

@media screen and (min-width: $screen-sm) {
	.nav-stacked-collapse.collapse {
		display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important;
	}
	.btn-toolbar-collapse.collapse {
		display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important;
	}
	
}
@media (min-width: $grid-float-breakpoint) {
	.navbar-form-collapse {
		display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important;
	}
}
.navbar-toggle {
	color: $brand-color;
	padding: 10px;
	font-size: 20px;
	line-height: 1;
	text-align: center;
	border-radius: 0;
	padding: 0;
	border-color: transparent !important;
	padding-bottom: 6px;
	&:hover{
		color: #fff;
	}
	&.pull-right {
		float: right;
	}
	.text-toggle {
		position: absolute;
		bottom: 3px;
		left: 0;
		right: 0;
		display: block;
		color: #f0f0f0;
		font-size: 10px;
	}
}
.navbar-form {
	.form-group {
		position: relative;	
		width: 100%;
		.form-control {
			padding-right: 40px;
		}	
		.btn {
			position: absolute;	
			right: 0;	
			top: 0;	
			padding: 5px 15px;
			&.btn-radius {
				border-radius: 50%;
			}
		}
		.btn-default {
			background: #bfc0c1;
			border-color: #bfc0c1;
			color: #fff;
		}
		.left {
			left: 0;
			right: auto;
		}
		.right {
			right: 0;
			left: auto;
		}
	}
	@media (max-width: $grid-float-breakpoint-max) {
		.form-group {
			.form-control {
				padding: 0 30px;
				
			}
			.btn  {
				width: 28px;
				height: 28px;
				top: 50%;
				font-size: 14px;
				margin-top: -14px;
				line-height: 1;
				padding: 5px !important;
			}
			.left {
				left: 4px;
			}
			.right {
				right: 4px;
			}
		}
	}

}
.navbar {
	@media (min-width: $grid-float-breakpoint) {
		.navbar-form-collapse {
			width: 100%;
			overflow: hidden;	
			margin-left: auto;	
			margin-right: auto;
		}
		.navbar-form {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.navbar-form {
		.form-group {
			.form-control {
				padding-right: 120px;
			}
		}
	}
	// @media (max-width: 1190px) {
	// 	> .container-fluid > .col-md-6 {
	// 		width: 38%;	
	// 	}
	// 	> .container-fluid > .col-md-3 {
	// 		width: 31%;	
	// 	}
	// }
	@media (max-width: $grid-float-breakpoint-max) {
		> .container-fluid > .col-md-6 {
			width: 100%;	
		}
		> .container-fluid > .col-md-3 {
			width: 100%;	
		}
	}
	
}
img {
	max-width: 100%;
	height: auto;
}
@media (max-width: $grid-float-breakpoint-max){
	.navbar-collapse {
		background: #2e4052;
		background: rgba(46,64,82,.95);
		position: fixed;
		left: 0;
		top: 0;

		bottom: 0;
		right: 0;
		width: 100%;
		z-index: 100;
		border-top: none;
		.navbar-nav {
			height: 100%;
		}
	}
	.navbar-form {
		display: table;
		box-shadow: none;
		border: none;
		> .form-group {
			display: table-cell;
			vertical-align: middle;
		}
		> .btn {
			display: table-cell;
			vertical-align: middle;
		}
	}
}
.navbar-collapse.collapsing {
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
	
}
@media (min-width: $grid-float-breakpoint) {
	.navbar-collapse.collapse {
		padding: 0;
	}
}


.navbar {
	border-radius: 0;
}

.navbar-nav {
	> li {
		> a , > span{
			text-transform: uppercase;
			font-family:'FranklinGothic-DemiCond', sans-serif;
			font-size: 16px;
			.badge {
				float: right;
				margin: -5px 0;
				display: none;
			}
		}
	}
	@media (max-width: $grid-float-breakpoint-max) {
		margin: 0;
		> li {
			border-bottom: 1px solid #506479;
			@include clearfix;
			> a, > span {
				font-size: 36px;	
				padding: 28px 16px;
				&.pull-left, &.pull-right {
					svg {
						color: $brand-color;
					}
				}
				.badge {
					display: inline-block;
				}
				@include sm {
					font-size: 24px;
					padding: 10px 12px;
				}
			}
			@include sm {
				margin-bottom: 4px;
			}
		}
	}
}
.navbar-brand {
	display: table;
	padding-top: 0;
	padding-bottom: 0;
	margin: auto;
}
.logo {
	display: table-cell;
	vertical-align: middle;
	img {
		display: block;	
		margin-top: -10px;
		@media (max-width: $grid-float-breakpoint-max) {
			width: 65%;
			margin: auto;
		}
	}
}

.page-header {
	position: relative;
	padding-top: 9px;
	margin: 0 0 10px 0;
	@include clearfix;
	h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5 {
		font-family:'FranklinGothic-DemiCond', sans-serif;
		font-weight: normal;
		float: left;
		margin: 0;
		padding-bottom: 2px;
	}
	.dropdown-toggle {
		float: right;
		padding: 6px;
	}
	.dropdown-toggle {
		display: none;
	}
	.btn-group {
		float: right;
		margin: 5px 0;
	}
	@include sm {
		.dropdown-toggle {
			display: block;
		}
		.btn-toolbar {
			float: none;
			
			clear: both;
			> .btn-group {
				display: table;
				margin: 10px 0;
				width: 100%;
				float: none;
				// table-layout: fixed;
				.btn {
					float: none;
					display: table-cell;
					font-family: 'Open Sans', sans-serif;
					font-size: 14px;
					text-transform: none;
					padding-left: 5px;
					padding-right: 5px;
					+ .btn {
						border-left: none;
					}
				}
			}
		}
		h1,h2,h3 {
			padding-top: 5px;
		}
	}
	
}
.btn {
	outline: none !important;
}

.page-header-border-top {
	border: none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 2px;
		width: 55px;
		background: $brand-color;
	}
}
.navbar-collapse {
	.page-header {
		margin-top: $gutter-vertical-sm;
		h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
			color: #fff;
		}
	}
	.btn-toolbar {
		margin-left: -20px;
	}
	.list-inline {
		.btn {
			color: #fff;
			border-color: #506479;
		}
	}
}
.navbar-inverse {
	.navbar-form {
		.btn-link {
			color: $brand-color;
		}
	}
}
.btn {
	font-family:'FranklinGothic-DemiCond', sans-serif;
	font-size: 16px;
	text-transform: uppercase;
	padding: 5px 15px;
}
.btn-toolbar {
	.btn-group {
		margin-left: 20px;
	}
}
.btn-group {
	@include xs {
		.btn {
			padding: 5px;
		}
	}
}
.btn-border {
	background-color: transparent;
	color: #37aaeb;
}
.page-header {
	@include clearfix;
}

.btn-primary.btn-border {
	color: #000;
	border-color: #cccccc;
	&:hover,
	&:focus,
	&.focus {
	  background: transparent;
	  color: #000;
	}
	@media (min-width: $grid-float-breakpoint) {
		&:hover,
		&:focus,
		&.focus {
		  background: $brand-color;
		  border-color: $brand-color;
		  color: #fff;
		}	
	}
	

	&:active,
	&.active {
	  background: $brand-color;
	  border-color: $brand-color;
	  color: #fff;
	}
}
.btn-group {
	.btn-primary.btn-border {
		border-color: $brand-color;
		color: #fff;
		@media (min-width: $grid-float-breakpoint) {
			&:active ,&:hover, &:focus{
				background: darken($brand-color, 10%);
				border-color: darken($brand-color, 10%);
				color: #fff;
			}
		}
			
	}
}


.list-thumbnails {
	overflow: hidden;
	margin-bottom: $gutter-vertical;
	@include sm {
		margin-bottom: $gutter-vertical-sm;
	}
	> ul {
		list-style: none;
		padding: 0;
		margin: 0 (-$grid-gutter-width/2) (-$grid-gutter-width);
		font-size: 0;
		> li {
			font-size: $font-size-base;
			display: inline-block;
			vertical-align: top;
			width: 16.66%;
			padding: 0 $grid-gutter-width/2 $grid-gutter-width + 5;
			@include md {
				width: 20%;
			}
			@include sm {
				width: 25%;
			}
			@include xs {
				width: 50%;
			}
		}
	}
}
.content .list-thumbnails {
	> ul {
		> li {
			width: 20%;
			@include md {
				width: 33.33%;
			}
			@include sm {
				width: 25%;
			}
			@include xs {
				width: 50%;
			}
		}
	}
}
.thumb-img {
	display: block;
	position: relative;
	border: 1px solid #ebebeb;
	img {
		display: block;
		width: 100%;
	}
}
.thumbnail {
	color: #888;
	position: relative;
	.caption {
		h3 {
			margin: 0;
			padding: 10px 0 4px 0;
			font-size: 16px;
			font-weight: 900;
			color: $brand-color;
			a {
				color: inherit;
			}
			&:hover {
				text-decoration: underline;
			}
		}
		p {
			@include text-overflow;
			margin-bottom: 0;
		}
	}
	&:hover {
		.caption {
			a {
				text-decoration: underline;
			}
		}
	}
}
.favorite-thumb, .category-thumb  {
	position: absolute;
	border-radius: 2px;
	padding: 6px;
	background: #000;
	background: rgba(0,0,0,.5);
	color: #fff;
	text-align: center;
	line-height: 1;
	@media (min-width: $grid-float-breakpoint) {
		&:hover, &:focus {
			background: $brand-color;
			color: #fff;
		}
	}
	&:hover, &:focus {
		color: #fff;
	}
	
}
.category-thumb {
	bottom: 5px;
	left: 5px;
	padding: 6px 12px;
	font-weight: bold;
}
.favorite-thumb {
	right: 5px;
	bottom: 5px;
	font-size: 20px;
	padding: 2px 6px;
	.icon-favorit-thumb-white {
		display: none;
	}
	&.active, &:active, &:focus {
		background: #2ecc71;
		.icon-favorit-thumb-white {
			display: inline-block;
		}
		.icon-favorit-thumb-white-stroke {
			display: none;
		}
	}
	@media (max-width: $grid-float-breakpoint-max) {
		display: none;
	}
}
.footer {
	margin-top: $gutter-vertical;	
	padding: 20px 0 10px;
	background: #e6e9ea;
	color: #222222;
	font-weight: 600;	
	@include sm {
		margin-top: $gutter-vertical-sm;
	}
	.col {
		width: (100% / 5);
		float: left;
		text-align: center;
	}
	@media (max-width: $grid-float-breakpoint-max) {
		padding: 0;
		.container-fluid {
			margin: 0 (-$grid-gutter-width/2);
		}
		.row-cols {
			border-bottom: 1px solid #d7d7d7;
			border-right: 1px solid #d7d7d7;
			@include clearfix;
		}
		.col {
			width: 50%;
			
			p {
				border-top: 1px solid #d7d7d7;
				border-left: 1px solid #d7d7d7;
				margin: 0;
				
				padding: 10px 5px;
				
			}
		}
	}
}
.list-inline {
		.btn {
			margin: 5px 0;	
		}
}
.pagination {
	margin: $gutter-vertical 0;	
	@include sm {
		margin: $gutter-vertical-sm 0;
	}
	> ul {
		margin: 0 -15px;
		height: 46px;	
		overflow: hidden;
		position: relative;	
		padding-right: 	50%;
		@include sm {
			margin: 0 -4px;
		}		
		> li {
			padding: 0 15px;
			@include sm {
				padding: 0 4px;
			}	
			&.pagination-dots {
				a, span {
					border-color: transparent;
					&:hover,
					&:focus {
					  color: $pagination-color;
					  background: darken($brand-color, 10%);
					  border-color: transparent;
					}
				}
			}
			&.pagination-next, &.pagination-previous {
				a {
					color: #fff;
					background: #37aaeb;
					border-color: #37aaeb;
				}
				.icon {
					font-size: 16px;
					vertical-align: baseline;
				}
			}
			&.pagination-next-lg {
				min-width: 50%;
				float: right;
				a, span {
					
				}
			}
			&.pagination-next-lg {
				width: 50%;
				position: absolute;	
				right: 0;	
				top: 0;		
				a, span {
					
				}
			}
			a, span {
				padding: 5px 10px;
				min-height: 46px;
				min-width: 	46px;
				font-size: 24px;
				font-weight: normal;	
				font-family:'FranklinGothic-DemiCond', sans-serif;
				&:hover {
					background: darken($brand-color, 10%);
					border-color: darken($brand-color, 10%);
				}
			}
			&.active {
				a, span {
					background: darken($brand-color, 10%);
					border-color: darken($brand-color, 10%);
					&:hover {
						border-color: darken($brand-color, 10%);
						background: darken($brand-color, 10%);
					}
				}
			}
		}
	}
}
.pager {
	margin: $gutter-vertical 0;
	@include sm {
		margin-bottom: $gutter-vertical-sm 0;
	}	
	> li {
		padding: 0 15px;
		@include sm {
			padding: 0 4px;
		}
		@include xs {
			display: block;
		}
		a, span, button {
			width: 400px;
			max-width: 100%;
			padding: 5px 10px;
			min-height: 46px;
			min-width: 	46px;
			font-size: 24px;
			font-weight: normal;	
			font-family:'FranklinGothic-DemiCond', sans-serif;
			color: #fff;
			background: #37aaeb;
			border-color: #37aaeb;
			&:hover {
				background: darken($brand-color, 10%);
				border-color: darken($brand-color, 10%);
			}
		}
	}
	
}
.list-inline-title {
	font-size: 24px;	
	color: #222;
	font-family:'FranklinGothic-DemiCond', sans-serif;
	float: 	left;
	padding: 4px 0;
	padding-right: 10px;	
}
#slider1_container , u.slides{
	width: 600px !important;
	height: 300px !important;
}
.hiden-sm {
	@include sm {
		display: none;
	}
}

.dropdown-toggle {
	font-size: 20px;
	text-align: center;
	background: none;
	border: 1px solid $brand-color;
	color: #222;
	padding: 8px 16px;
	font-size: 14px;
	font-family: "Open Sans", sans-serif;
	text-transform: none;
	text-align: left;
	i {
		color: $brand-color;
		font-size: 20px;
		line-height: 1;
	}
	&[aria-expanded="true"] {
		color: #fff;
		background-color: $brand-color;
		border-color: $brand-color;
	}
	&:active {
		color: #fff;
		background: $brand-color;
		border-color: $brand-color;
	}
	.icon {
		font-size: 20px;
		color: $brand-color;
	}
	.icon-keyboard-arrow-down {
		display: inline-block;
	}
	.icon-keyboard-arrow-up {
		display: none;
	}
	&[aria-expanded="true"] {
		.icon {
			color: #fff;
		}
		.icon-keyboard-arrow-down {
			display: none;
		}
		.icon-keyboard-arrow-up {
			display: inline-block;
		}
	}
}
.dropdown-toggle-ico {
	padding: 5px;
	width: 35px;
	height: 35px;
	line-height: 1;
	font-size: 20px;
	text-align: center;
	.icon  {
		vertical-align: baseline;
		color: $brand-color;
	}
	&[aria-expanded="true"] {
		.icon  {
			color: #fff;
		}
	}
}



.info-box {
	position: relative;
	float: left;
	width: 350px;
	@include sm {
		float: none;
		width: auto;	
	}
}
.fotorama-container {
	width: 100%;
	padding-right: 380px;
	margin-right: -350px;
	margin-bottom: 20px;
	position: relative;
	float: left;
	overflow: hidden;
	@include sm {
		float: none;
		margin: 0;
		padding: 0;
		width: auto;
	}
}

.fotorama__html {
	text-align: center;
}
.fotorama__thumb-border {
	display: none;
}
.fotorama-container .fotorama__thumb {
	position: relative;
}
.fotorama__nav--thumbs .fotorama__nav__frame .fotorama__thumb {
	border: 1px solid #363636;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #24a3ed;
		@include opacity(0);
		z-index: 2;
		transition: opacity .2s;
	}
}
.fotorama__nav--thumbs .fotorama__nav__frame.fotorama__active .fotorama__thumb{
	&:before {
		@include opacity(.8);
	}
}
.fotorama__wrap {
	border: 1px solid #292929;
}
@media (min-width: $grid-float-breakpoint) {
	.fotorama-container {
		padding-left: 300px;
	}
	.fotorama-container .fotorama {
		// max-width: 500px;
	}
	.fotorama-container .fotorama__wrap {
		position: static;
	}
	.fotorama-container .fotorama__nav-wrap {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		max-width: 300px;
		// height: 100%;
		overflow-y: auto;
	}
	.fotorama-container .fotorama__nav__shaft {
		// text-align: center;
		transform: translate3d(0, 0, 0) !important;
	}
	.fotorama-container .fotorama__nav {
		width: 100% !important;
		white-space: normal;
	}
	
	.fotorama-container .fotorama__nav:after, .fotorama__stage:after {
		display: none;
	}
	.fotorama-item {
		text-align: center;	
		height: 100%;
		position: relative;		
	}
	.fotorama-container .fotorama-img {
		img {
			display: block;
			width: 100%;
		}
	}


}
.fotorama-img {
	border: 1px solid #303030;
}
.fotorama-info {
	@include clearfix;
	padding: 18px 0;
	.box-share {
		float: left;
		color: #9da1a2;
	}
	.box-views {
		float: right;
		color: #9da1a2;
	}
}



.box-views, .box-share {
	span, i {
		margin: 0 4px;
	}
}
.fullscreen .fotorama-item {
	height: 100%;
	img {
		height: 100%;	
	}
}
.fullscreen .fotorama-img {
	height: 100%;
	border: none;
}
.fotorama--fullscreen .fotorama__nav, .fotorama--fullscreen .fotorama__stage {
	background: #292929;
}
.form-information .ui-selectmenu-button, .form-information input[type='text'] {
	vertical-align: middle;
	display: inline-block;
}
.form-information label {
	vertical-align: middle;	
}

$size-btns: 44px;
.fotorama__arr, .fotorama__video-close, .btn-favorite , .fotorama__fullscreen-icon , .btn-play , .btn-autoplay-speed{
	width: $size-btns;	
	height: $size-btns;	
	line-height: $size-btns;
	background:	 #000;
	padding: 0;
	background:	rgba(0,0,0,.7);
	font-family: 'icomoon';
	text-align: center;	
	color: #d1d5d6;
	border-radius: 4px; 
	font-size: 20px;	
	&:hover, &:focus {
		color: #fff;
	}
}
.btn-autoplay-speed {
	position: absolute;
	@include opacity(0);
	font-weight: bold;
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
	text-transform: none;
}
.fotorama__arr, .fotorama__video-close, .fotorama__fullscreen-icon, .btn-play, .btn-autoplay-speed {
	&:hover, &:focus {
		color: #fff;
		background: darken($brand-color, 10%);
	}
}
.btn-favorite {
	&.active ,&:hover, &:focus{
		background: $brand-success;
		color: #fff;
	}
}
.fotorama__fullscreen-icon {
	right: 10px;
	top: 65px;
}
.btn {
	input[type="radio"] {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}
.btn-play {
	position: absolute;
	top: 10px;
	left: 10px;
	
	.icon {
		vertical-align: baseline;
	}
	.icon-pause {
		display: none;
	}
	&.active {
		.icon-pause {
			display: inline;
		}
		.icon-play {
			display: none;
		}
	}
}
.btn-autoplay-speed {
	left: 10px;
	top: 65px;
}
.btn-success {
	@media (min-width: $grid-float-breakpoint) {
		&:active ,&:hover, &:focus{
			background: darken($brand-color, 10%);
			border-color: darken($brand-color, 10%);
			color: #fff;
		}
	}
	
}
.fotorama-holder {
	position: relative;
}
// @media screen (max-width: $grid-float-breakpoint-max){
// 	.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr, .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
// 		opacity: 1;
// 		transform: translate3d(0,0,0);
// 	}
// 	.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
// 		opacity: 1;
// 		transform: translate3d(0,0,0);
// 	}
// }
.fotorama__fullscreen-icon {
	&:before {
		content: "\e607";
	}

}
.fullscreen .fotorama__fullscreen-icon {
	&:before {
		content: "\e60c";
	}

}
.fotorama__arr--prev {
	left: 10px;
	&:before {
		content: "\e60a";
	}
}
.fotorama__arr--next {
	right: 10px;
	&:before {
		content: "\e609";
	}
}
.btn-favorite {
	position: absolute;	
	top: 10px;
	right: 10px;	
	.icon {
		vertical-align: baseline;
	}
}
.fotorama__stage__shaft {
	.btn-favorite {
		@include opacity(1);
		transition: opacity .2s;
	}
}
.fotorama__grabbing {
	.btn-favorite {
		@include opacity(0);
	}
}
.fotorama__wrap--css3 .fotorama__arr, .fotorama__wrap--css3 .fotorama__fullscreen-icon, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border, .fotorama__wrap--css3 .fotorama__video-close, .fotorama__wrap--css3 .fotorama__video-play, .fotorama__wrap--css3 .btn-favorite {
    transform: translate3d(0, 0, 0)
}
// .fotorama__wrap--no-controls .btn-favorite {
//     transform: translate3d(-$size-btns, -$size-btns, 0)
// }
.fotorama__wrap--css3 .btn-favorite:not(:focus) {
    transition-property: transform, opacity;
    transition-duration: .3s
}


.fotorama__wrap--css3 .fotorama__video-close {
    -webkit-transform: translate3d($size-btns, -$size-btns, 0);
    transform: translate3d($size-btns, -$size-btns, 0)
}
.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    -webkit-transform: translate3d($size-btns, -$size-btns, 0);
    transform: translate3d($size-btns, -$size-btns, 0)
}
@media (min-width: $grid-float-breakpoint) {
	.fotorama-container .fotorama__nav--thumbs .fotorama__nav__frame {
		padding-top: 0 !important;
	}
	.fotorama {
		.fotorama__fullscreen-icon, .btn-play, .btn-favorite {
			@include opacity(0);
			transition: opacity .2s;
			
		}
	}

	.fotorama {
		&:hover {
			.fotorama__fullscreen-icon, .btn-play, .btn-favorite, .btn-autoplay-speed {
				@include opacity(1);
			}
			
		}
	}
}

.well-danger {
	margin: 0;
	padding: 0;
	padding-bottom: 10px;
	background: #292929;
	border-color: #292929;
	color: #9da1a2;
	.list-inline-title {
		color: #d1d5d6;
	}
	.btn-primary.btn-border {
		color: #fff;
		border-color: #506479;
	}
	.btn-primary.btn-border:hover, .btn-primary.btn-border:focus, .btn-primary.btn-border.focus {
		color: #fff;
	}
	.well {
		background: #363636;
		border-color: #363636;
		padding: 10px;
	}
	@include sm {
		padding: 0;
	}
	h1,h2,h3,h4,h5 {
		color: #d1d4d6;
		margin: 30px 0;
	}
}

// info-fotorama
$info-vertical-gutter: 14px;
.info-box {
	.table {
		color: #d1d5d6;
		margin: $info-vertical-gutter 0;
		tr {
			td {
				border-color: #363636;
				.text-muted {
					color: #9da1a2;
				}
			}
		}
	}
	.btn-group {
		margin: $info-vertical-gutter 0;
		.btn-group {
			+ .btn-group {
				padding-left: 20px;
			}
		}
	}
	.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
		border-radius: 3px;
	}
	.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
		border-radius: 3px;
	}
}
.info-title {
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	display: block;
	margin: 0 0 $info-vertical-gutter/2 0;
}
.info-img {
	display: block;
	position: relative;
	img {
		display: block;
		width: 100%;
	}
	.info-rating {
		position: absolute;
		top: 0;
		left: 0;
		color: #fff;
		font-size: 24px;
		width: auto;
		padding: 5px 10px;
		margin: 0;
		border: none;
		text-align: center;
		sup {
			font-size: 14px;
			top: -0.5em;
			margin-left: -0.2em;
			

		}
	}
}
.info-stars {
	
}
.info-rating {
	float: left;
	width: 50%;
	margin: $info-vertical-gutter 0;
	font-weight: bold;
	font-size: 72px;
	color: #d1d5d6;
	border: 1px solid #464646;
	border-right: 0;
	line-height: 1;
	display: block;
	text-align: center;
	padding: 16px 0px;
	padding-right: 15px;
	position: relative;
	z-index: 2;
	overflow: hidden;
	sup {
		font-size: 24px;
		top: -1.5em;
		margin-left: -.8em;
	}
	+ .list-stars {
		padding-left: 10px;
	}
	&:before, &:after {
		content: '';
		position: absolute;
		z-index: -1;
		right: 5%;
		height: 54%;
		width: 1px;
		background: #464646;
	}
	
	&:before {
		top: -2px;
		transform: rotateZ(20deg);
	}
	&:after {
		bottom: -2px;
		transform: rotateZ(-20deg);
	}
}
.info-rating-bg {
	background: transparent;
	min-width: 90px;
	padding-right: 20px !important;
	&:before, &:after {
		border-width: 17px;
		background: transparent;
		border-style: solid;
		border-color: $brand-color;
		transform: rotateZ(0);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 0;
		height: 0;
	}
	&:before {
		border-right-color: transparent;
		border-left-width: 70px;
	}
	&:after {
		display: none;
	}
}
.no-csstransforms3d {
	.info-rating {
		&:before, &:after {
			right: 0;
		}
	}
}
.list-stars {
	overflow: hidden;
	margin: $info-vertical-gutter 0;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 0;
		li {
			font-size: 16px;
			float: right;
			
			color: #c3c3c3;
			padding: 0 2px;
			a {
				color: inherit;
			}
			.icon {
				vertical-align: baseline;
				font-size: 12px;	
			}
			.icon-blue-star {
				display: none;
			}
			&:hover {
				color: $link-color;
				.icon-blue-star {	
					display: inline-block;
				}
				.icon-gray-star {
					display: none;
				}
			}
			&:hover ~ li, &.active ~ li  {
				color: $link-color;
				.icon-gray-star {
					display: none;
				}
				.icon-blue-star {
					display: inline-block;
				}
			}
		}
	}
}
.stars-title {
	font-size: 13px;
	min-width: 70px;
	display: inline-block;
	color: #9da1a2;
}
.btn-radius {
	border-radius: 3px;
}
.title-table {
	color: #9da1a2;
	padding-right: 5px;
}

.list-spots {
	text-align: center;
	color: #222;
	> ul {
		list-style: none;
		margin: 0 -6px;
		padding: 0;
		font-size: 0;
		height: 250px;
		overflow: hidden;
		li {
			padding: 0 6px;
			font-size: $font-size-base;
			display: inline-block;
			vertical-align: top;
		}
	}
}
.spot {
	display: block;
	width: 300px;
	height: 250px;
	background: url(../images/banner-front.png) no-repeat;
	background-size: cover;
	img {
		display: block;
		width: 100%;
	}
}
.title-spot {
	color: #222;
	font-family:'FranklinGothic-DemiCond', sans-serif;
	font-size: 16px;
	text-transform: uppercase;
	margin: 0 0 5px 0;
	display: inline-block;
}
.btn-toolbar {
	margin-left: 0;
}

.length-thumbs {
	border: 1px solid #363636;
	display: inline-block;
	text-align: center;
	padding: 2px 8px;
	margin: 0 0 20px 0;
	font-size: 14px;
	color: #d1d4d6;
	font-weight: bold;
	border-radius: 25px;
	@media (max-width: $grid-float-breakpoint-max) {
		&.visible-breakpoint {
			display: inline-block;
		}
	}
}
.active-thumb, .all-length{
	padding: 0 5px;
}

.visible-breakpoint, .nav > li.visible-breakpoint {
	display: none;
	@media (max-width: $grid-float-breakpoint-max) {
		display: block;
	}
}
.heading-fotorama {
	@media (max-width: $grid-float-breakpoint-max) {
		text-align: center;
	}
}
.info-img {
	.info-rating {
		display: none;
	}
}
@media (max-width: $grid-float-breakpoint-max) {
	.well-danger {
		color: #fff;
	}
	.info-img {
		.info-rating {
			display: block;
		}
	}
	
}
@include sm {
	.info-rating, .list-stars, .info-box .table {
		display: none;
	}
	.well-danger .well {
		background: transparent;
		border-left: none;
		border-bottom: none;
		border-right: none;
	}
}

@media (max-width: $grid-float-breakpoint-max) {
	.hide-breakpoint {
		display: none !important;
	}
}
.fotorama-container .fotorama-item {
	// padding-bottom: 40px;
	height: 100%;
}
.fotorama-container .fotorama-img {
	height: 100%;
	img {
		height: 100%;
	}
}
.visible-breakpoint-inline {
	display: none !important;
}
.pagination {
	> ul > li {
		&.pagination-info {
			display: none;	
		}
	}
}
@media (max-width: $grid-float-breakpoint-max) {
	.fotorama-container {
		min-height: 0 !important;
	}
	.btn-favorite {
		display: none !important;
	}
	.fotorama__fullscreen-icon {
		top: 10px;
	}
	.btn-autoplay-speed {
		@include opacity(1);	
	}
	.fotorama-info {
		padding-top: 0;
	}
	.fotorama-info .box-share {
		display: none;
	}
	.fotorama-info .box-views {
		float: none;
		text-align: center;
	}
	.visible-breakpoint-inline {
		display: inline-block !important;
	}
	.pagination {
		> ul {
			display: table;	
			width: 100%;
			padding: 0;		
		}
		> ul > li {
			display: none;
			> a i,> span i {
				font-size: 30px;
				display: block;	
			}
			&.pagination-next, &.visible-breakpoint-inline, &.pagination-info {
				display: table-cell !important;
				vertical-align: middle;		
				position: static;
				width: 1%;
				white-space: nowrap;		
			}
			&.pagination-next-lg {
				width: 100%;		
			}
			&.pagination-info {
				padding: 0 10px;	
				> span, > a  {
					font-family: 'Open Sans', sans-serif;
					font-weight: bold;	
					border: none;
					border-radius: 0;
					width: auto;
					height: auto;
					min-width: 0;
					min-height: 0;
					padding: 0 2px;	
					font-size: 14px;
					display: inline-block;	
					vertical-align: middle;	
					&:hover {
						background: transparent;
						color: #222;	
					}	
				}
			}
		}
	}
}
.readmore {
	white-space: nowrap;
}
.text-dot {
	&.opened {
		.readmore {
			display: none;
		}
	}
}


.sidebar {
	.navbar-form {
		padding: 0;	
		margin: 0;
		@include sm {
			display: none;
		}
		.btn {
			padding: 5px 9px;
		}
	}
	.dropdown-toggle {
		display: none;
	}
	.nav-stacked {
		margin: 10px 0;
		> li {
			> a {
				font-weight: bold;
				padding: 4px 15px;
			}
		}
	}
	@include sm {
		padding-bottom: 10px;
		.nav-stacked {
			@include clearfix;
			> li {
				float: left;
				width: 50%;
			}
		}
		.dropdown-toggle {
			display: block;
		}
	}
	@media (max-width: $grid-float-breakpoint-max) {
		.form-group {
			.btn  {
				width: 32px;
				height: 32px;
				top: 50%;
				font-size: 14px;
				margin-top: -16px;
				line-height: 1;
				padding: 7px !important;
			}
		}
	}

}

// svg 

.icon-logo {
	font-size: 5em;
	max-width: 100%;
	width: 230px;
	height: $navbar-height;
	margin-top: -10px;
	@media (max-width: $grid-float-breakpoint-max) {
		height: 40px;
		margin-top: 0;
		width: 150px;
	}
}
#icon-logo .path1 {
	fill: rgb(121, 210, 247);
}
#icon-logo .path2 {
	fill: rgb(82, 178, 228);
}
#icon-logo .path3, #icon-logo .path11, #icon-logo .path12, #icon-logo .path13, #icon-logo .path14 {
	fill: rgb(62, 150, 210);
}
#icon-logo .path4, #icon-logo .path5, #icon-logo .path6, #icon-logo .path7, #icon-logo .path8, #icon-logo .path9, #icon-logo .path10 {
	fill: rgb(241, 240, 240);
}
.no-inlinesvg {
	*[class*=icon-] {
		background-size: 100%;
		width: 20px;
		height: 20px;
	}
	.icon-keyboard-arrow-up {
		background: url(../images/keyboard-arrow-up.png) 50% 50% no-repeat;
	}
	.icon-keyboard-arrow-down {
		background: url(../images/keyboard-arrow-down.png) 50% 50%  no-repeat;
	}
	.icon-logo {
		width: 222px;
		height: 60px;
		background: url(../images/logo.png) 50% 50%  no-repeat;
	}
	.icon-eye {
		background: url(../images/eye.png) 50% 50%  no-repeat;
	}
	.icon-favorits-blue {
		background: url(../images/menu-favorits-blue.png) 50% 50%  no-repeat;
	}
	.icon-favorit-thumb-white {
		background: url(../images/favorite-thumb-white.png) 50% 50%  no-repeat;
	}
	.icon-favorit-thumb-white-stroke {
		background: url(../images/favorite-thumb-white-stroke.png) 50% 50%  no-repeat;
	}
	.icon-home {
		background: url(../images/mobile-home.png) 50% 50%  no-repeat;
	}
	.icon-mobile-exit {
		background: url(../images/mobile-exit.png) 50% 50%  no-repeat;
	}
	.icon-mobile-left {
		background: url(../images/mobile-left.png) 50% 50%  no-repeat;
	}
	.icon-search {
		background: url(../images/search.png) 50% 50%  no-repeat;
	}
	.icon-settings {
		background: url(../images/settings.png) 50% 50%  no-repeat;
	}
	.icon-gray-star {
		background: url(../images/gray-star.png) 50% 50%  no-repeat;
	}
	.icon-blue-star {
		background: url(../images/blue-star.png) 50% 50%  no-repeat;
	}
}
.banner-mobile {
	text-align: center;
	margin: 10px 0;
	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}
}

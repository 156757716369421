//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  padding-left: 0;
  margin: $line-height-computed 0;
  border-radius: $border-radius-base;

  > ul {
    margin: 0;  
    padding: 0; 
    list-style: none; 
    font-size: 0;
    > li {
      font-size: $font-size-base;
      display: inline-block; // Remove list-style and block-level defaults
      vertical-align: top;
      > a,
      > span {
        text-align: center;
        position: relative;
        display: block;
        padding: $padding-base-vertical $padding-base-horizontal;
        line-height: $line-height-base;
        text-decoration: none;
        color: $pagination-color;
        background: $pagination-bg;
        border: 1px solid $pagination-border;
        margin-left: -1px;
        border-radius: $border-radius-base;
      }
      &:first-child {
        > a,
        > span {
          margin-left: 0;
        }
      }
      &:last-child {
        > a,
        > span {
        }
      }
    }

    > li > a,
    > li > span {
      &:hover,
      &:focus {
        color: $pagination-hover-color;
        background: $pagination-hover-bg;
        border-color: $pagination-hover-border;
      }
    }

    > .active > a,
    > .active > span {
      &,
      &:hover,
      &:focus {
        z-index: 2;
        color: $pagination-active-color;
        background: $pagination-active-bg;
        border-color: $pagination-active-border;
        cursor: default;
      }
    }

    > .disabled {
      > span,
      > span:hover,
      > span:focus,
      > a,
      > a:hover,
      > a:focus {
        color: $pagination-disabled-color;
        background: $pagination-disabled-bg;
        border-color: $pagination-disabled-border;
        cursor: $cursor-disabled;
      }
    }
  }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  @include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

// Small
.pagination-sm {
  @include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
